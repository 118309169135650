<template>
  <div class="ltoggle">
    <el-collapse-transition>
      <div v-if="openSearchForm">
        <slot></slot>
      </div>
    </el-collapse-transition>
    <div class="buttonBox" v-if="openSearchForm">
      <template v-for="(item, index) in FormButton">
        <el-button
          class="elbutton"
          :key="index"
          :type="item.type"
          :size="item.size || 'small'"
          :icon="item.icon"
          :plain="item.plain"
          :round="item.round"
          :circle="item.circle"
          :loading="item.loading"
          :disabled="item.disabled"
          :autofocus="item.autofocus"
          :native-type="item.native"
          @click="item.click"
        >
          {{ item.name }}
        </el-button>
      </template>
    </div>
    <div class="toggleSearchForm" v-if="openFormBtnShow">
      <el-button
        type="text"
        size="small"
        :icon="openSearchForm ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"
        @click="openSearchForm = !openSearchForm"
      >
        {{ openSearchForm ? '收起筛选' : '展开筛选' }}
      </el-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LToggle',
  props: {
    FormButton: {
      type: Array,
      default: () => [],
    },
    openForm: {
      type: Boolean,
      default: true,
    },
    openFormBtnShow: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      openSearchForm: this.openForm,
    };
  },
};
</script>

<style lang="scss" scoped>
@import '@/element/element-variables.scss';
.ltoggle {
  position: relative;
  background: #ffffff;
  padding: 20px;
  .buttonBox {
    display: flex;
    justify-content: flex-end;
  }
  .toggleSearchForm {
    text-align: center;
  }
}
</style>
